<template>
    <div>
        <!--eslint-disable-next-line vue/no-mutating-props-->
        <select :name="context.name" :id="context.id" :class="['formulate-input', context.className]" v-model="context.model" v-bind="context.attributes">
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <div v-if="selectedOption" class="selected-option">
            <img :src="selectedOption.image" :alt="selectedOption.label" />
            <span>{{ selectedOption.label }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['context'], // VueFormulate context prop
    computed: {
        model() {
            return this.context.model;
        },
        options() {
            // Handle options passed as an array or an object
            if (Array.isArray(this.context.options)) {
                return this.context.options;
            }
            return Object.entries(this.context.options).map(([value, label]) => ({
                value,
                label
            }));
        },
        selectedOption() {
            return this.options.find(option => option.value === this.context.model);
        }
    }
};
</script>

<style>
.selected-option {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

.selected-option img {
    width: 64px;
    height: 64px;
    border-radius: 4px;
}

.selected-option span {
    font-size: 14px;
}
</style>