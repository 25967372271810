<template>
    <div :name="context.name" :id="context.id" class="custom-select-input">
      <input v-model="inputValue" @focus="showOptions" :class="[{'is-options-open': focused}, {'empty-label': !label}, 'select-input']" type="text">
      <span v-if="label" :class="[{'is-focus': inputValue}, 'label']">{{ label }}</span>
      <transition name="fade" mode="in-out">
        <div v-show="focused" class="options">
          <div v-for="(option, i) in filteredOptions" :key="i" @click="selectOption(option)" :class="[{'is-searched': option.searched}, 'select-item']">{{ option.label }}</div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
    export default {
      props: {
        context: {
            type: Object
        },
        label: {
          type: String
        }
      },
      data () {
        return {
          inputValue: '',
          selectValue: null,
          focused: false,
          searched: false,
          selectedOption: null
        }
      },
      computed: {
        filteredOptions () {
          let result = []
          this.context.options.forEach((item) => {
            item.searched = false
            let lowerValue = this.inputValue
            if (this.inputValue) {
              lowerValue = this.inputValue.toLowerCase()
            }
            if (item.label.toLowerCase().includes(lowerValue)) {
              if (this.searched && this.inputValue.trim() !== "") {
                item.searched = true
              }
              result.unshift(item)
            }
          })
          if (this.inputValue) {
            return result
          }
          return result.reverse()
        }
      },
      watch: {
        inputValue (value) {
          if (!value) {
            this.selectValue = null
          }
        }
      },
      mounted () {
        document.documentElement.addEventListener('click', this.outsideClick, false)
        if (this.label && this.label.trim() === "") {
          // eslint-disable-next-line vue/no-mutating-props
          this.label = false
        }

        this.selectedOption = this.context.options.find( opt => opt.value == this.context.value );
        if( this.selectedOption ) {
            this.inputValue = this.selectedOption.label;
        }
      },
      beforeDestroy () {
        document.documentElement.removeEventListener('click', this.outsideClick, false)
      },
      methods: {
        showOptions () {
          this.inputValue = ''
          this.focused = true
        },
        hideOptions () {
          this.searched = false
          this.$nextTick(() => {
            this.focused = false
            if( this.selectedOption ) {
                this.inputValue = this.selectedOption.label;
            }
          })
        },
        selectOption (option) {
          delete option.searched
          this.selectValue = option && option.value ? option.value : option;
          this.inputValue = option.label;
            
          if( !this.selectedOption || option.value != this.selectedOption.value ) {
            // eslint-disable-next-line vue/no-mutating-props
            this.context.model = this.selectValue;
            this.$emit('input', this.selectValue)
            this.$emit('selected', this.selectValue);
            this.hideOptions()
          }

          this.selectedOption = option;
        },
        outsideClick (e) {
          if (!this.$el.contains(e.target)) {
            this.hideOptions();
          }
        }
      }
    }
  </script>
  
  <style scoped>
    .custom-select-input {
      position: relative;
      display: flex;
      align-items: center;
      min-width: 300px;
    }
  
    .select-input {
      border: 1px solid rgba(0,0,0,.2);
      outline: none;
      padding: 20px 15px 4px;
      border-radius: 4px;
      transition: all .3s ease;
      z-index: 5;
      width: 100%;
      height: 100%;
    }
  
    .select-input.empty-label {
      padding: 5px 15px;
    }
  
    .select-input:focus, .select-input:valid {
      outline: 0;
    }
  
    .select-input:focus {
      border-color: #a4d8c2;
    }
  
    .select-input:focus + .label {
      top: 8px;
      font-size: 12px;
      margin-top: 0;
    }
  
    .select-input.is-options-open {
      border-radius: 4px 4px 0 0;
      border-bottom-color: rgba(0,0,0,.2);
    }
  
    .label {
      position: absolute;
      left: 15px;
      font-size: 14px;
      color: #9299a2;
      color: rgba(51,51,51,.54);
      pointer-events: none;
      transition: all .3s ease;
      z-index: 6;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(300px - 30px);
    }
  
    .label.is-focus {
      top: 8px;
      font-size: 12px;
      margin-top: 0;
    }
  
    .options {
      width: 100%;
      position: absolute;
      top: 97%;
      background: #fff;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 0 0 4px 4px;
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;
      transition: all .3s ease;
      z-index: 4;
      left:0;
      right:0;
    }
  
    .select-item {
        text-overflow: ellipsis;
      cursor: pointer;
      transition: all .3s ease;
      padding: 0 15px;
      white-space: nowrap;
      color: black;
    }
  
    .select-item.is-searched, .select-item:hover {
      background: silver;
    }
  
    .fade-enter-active,
    .fade-leave-active {
      transition: all .4s ease;
    }
    .fade-enter,
    .fade-leave-active {
      opacity: 0;
      will-change: opacity;
      transform: translateY(-30px);
    }
  
  </style>