import Vue from 'vue';
import Vuex from 'vuex';

import games from "./games";
import accounts from "./accounts";
import helpers from "./helpers";
import orders from "./orders";
import misc from "./misc";
import workers from "./workers";
import logs from "./logs";
import eldoradoOffers from "./eldorado-offers";
import user from "./user";
import eldoradoBoosting from "./eldorado-boosting"
import zeusxOffers from "./zeusx-offers";
import g2gOffers from "./g2g-offers";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    games,
    accounts,
    helpers,
    orders,
    misc,
    workers,
    logs,
    eldoradoOffers,
    user,
    eldoradoBoosting,
    zeusxOffers,
    g2gOffers
  }
});
 