export default {
   setStats: (state,payload) => {
      state.stats = payload;
   },
   setAllOrders: (state,payload) => {
      state.allOrders = payload;
   },
   setForExportStats: (state,payload) => {
      state.statsForExport = payload;
   },
   setProfit: (state,payload) => {
      state.profit = payload;
   }
}